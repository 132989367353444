import React from "react";
import PropTypes from "prop-types";

const Button = ({ onClick, children, isSecondary, ...rest }) => {
  return (
    <>
      {isSecondary ? (
        <button
          className="h-12 mx-auto md:mx-2 max-w-xs whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-indigo-600 text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline active:bg-indigo-700 transition ease-in-out duration-150"
          type="button"
          onClick={onClick}
          {...rest}
        >
          {children}
        </button>
      ) : (
        <button
          className="h-12 mx-auto md:mx-2 max-w-xs whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline active:bg-indigo-700 transition ease-in-out duration-150"
          type="button"
          onClick={onClick}
          {...rest}
        >
          {children}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  isSecondary: PropTypes.bool,
};

export default Button;
